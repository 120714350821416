@import 'prismjs/themes/prism-coy.css';

#PortalDashboard {
    min-height: calc(100vh - 109px);
    padding: 16px 54px;
    background: #1f2531;
    color: #F5F5F5;
    .tagsSecondary .p-button{
        background: #42454C !important;
        border-left: 1px solid #5d6068 !important;
        &.p-highlight {
            background: #202225 !important;
        }
        &:hover {
            background: #8b8a8e !important;
        }
        
    }

    .p-selectbutton .p-button {
        margin-bottom: 10px;
        margin-right: 10px;
        background: #477fdf;
        border-left: 1px solid #6591DC;
        border-right: none;
        border-top: none;
        border-bottom: none;
        border-radius: 25px;
        &.p-highlight {
            color: #FAFAFA;
            background: #243858;
            font-weight: bold;
        }
        &:hover {
            color: #FAFAFA;
            background: #CBC3E3;
        }
    }
    .box-container {
        padding: 12px;
        //border: 1px solid #3F4B5B;
        border-radius: 4px;
        display:grid;
        //background: #2A323D;
        grid-template-columns: 25% 25% 25% 25%;
    }
    .app-box {
        display: inline-block;
        margin: 12px;
        padding: 16px;
        border-radius: 4px;
        vertical-align: top;
        background: #313849;
        border: 1px solid #394156;
        box-shadow: 4px 4px 4px rgba(10, 10, 10, 0.3);

        &:hover {
            cursor: pointer;
            background: #424861;

            .tag {
                background: #3a424d;
            }
        }
        .thumbnail {
            //width: calc(25vw - 90px);
            left: 100%;
            right:100%;
            img {
                border-radius: 4px;
                width: 100%;
                margin: auto;
                object-fit: contain;
                filter: drop-shadow( 0px 5px 10px #20262e);
            }
            i {
                position: absolute;
                top: 6px;
                right: 6px;
                padding: 4px;
                border-radius: 4px;
                font-size: 2rem;
                background-color: rgba(220, 220, 220, 0.7);
            }
        }
        h2 {
            margin-top: 8px;
        }
        .detail {
            display: inline-block;
            width: calc(50% - 8px);
            margin: 12px 8px 16px 0;
            vertical-align: top;
            color: #A0C3FF;
            overflow-x: hidden;
            font-size: 1.2rem;

            > div {
                font-size: 1rem;
                color: #F5F5F5;
            }
        }
        .tag {
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 5px;
            padding: 4px 10px;
            border: 1px solid #333;
            border-radius: 16px;
            background: #1f2531;
        }
    }
}

#AppDetails {
    min-height: calc(100vh - 109px);
    padding: 16px 64px;
    background: #20262E;
    color: #F5F5F5;
    display: grid;
    grid-template-columns: 70% auto;

    .img-section {
        display: grid;
        grid-template-columns: 200px auto;
        .img-list {
            width: 200px;
            vertical-align: top;
            img {
                width: 100%;
                min-height: 80px;
                max-height: 120px;
                margin: 8px 0;
                padding: 2px;
                border: 2px solid rgba(255, 255, 255, 0);
                border-radius: 4px;
                object-fit: contain;
                background: #2A323D;

                &:hover {
                    cursor: pointer;
                }
                &.selected {
                    border: 2px solid #F5F5F5;
                }
            }
        }
        .full-img {    
            margin-top: 8px;
            margin-left: auto;
            margin-right: auto;
            img {
                display: inline-block;
                max-width: 100%;
                min-width: 30%;
                object-fit: contain;
                padding-left: 50px;
                padding-right: 50px;
            }
        }
    }
    .detail-section {

        .tags {
            margin-bottom: 24px;

            .tag0{
                background: #477FDF;
                border:1px solid #477FDF;
                color:white
            }
            > div {
                display: inline-block;
                margin-right: 8px;
                padding: 2px 8px;
                border: 1px solid #E3ECFD;
                border-radius: 10px;
                background: #E3ECFD;
                color: black;
            }
        }
        .app-metadata{
            display:grid;
            grid-template-columns: 50% 50%;
            grid-row-gap: 15px;
            margin-bottom: 15px;
            .mdTitle{
                font-weight: 600;
                font-size: 15px;
            }
        }
        .app-description{
            margin-bottom: 15px;
        }

        a {
            display: inline-block;
            width: 100%;
            padding: 2px 4px 4px 4px;
            border: 1px solid #3F4B5B;
            border-radius: 2px;
            overflow-x: hidden;
            background: #2A323D;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .p-tabview {
        margin-bottom: 32px;

        .p-tabview-nav-container li {
            margin-right: 2px;
        }
        .p-tabview-panels {
            background: #4D5158;
            color: #F5F5F5;
            border: 1px solid #3F4B5B;

            .tab-column {
                display: inline-block;
                width: 20%;
                vertical-align: top;

                span {
                    font-weight: bold;
                }
                p {
                    margin-left: 2px;
                }
            }
        }
    }
}